@layer modules, ui, base;
@layer base {
  .Links_title__rehRz {
  margin-bottom: var(--spacer-400);
}

.Links_list__dWdaH {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacer-300);
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 48rem) {
  .Links_title__rehRz {
    margin-bottom: var(--spacer-600);
    font-size: var(--headingSmall);
    line-height: var(--headingSmallLineHeight);
  }

  .Links_list__dWdaH {
    grid-template-columns: 1fr 1fr;
    gap: var(--spacer-800) calc(var(--spacer-800) + var(--spacer-300));
  }
}

}
