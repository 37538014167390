@layer base {
  .title {
  margin-bottom: var(--spacer-400);
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacer-300);
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (--medium-viewport) {
  .title {
    margin-bottom: var(--spacer-600);
    font-size: var(--headingSmall);
    line-height: var(--headingSmallLineHeight);
  }

  .list {
    grid-template-columns: 1fr 1fr;
    gap: var(--spacer-800) calc(var(--spacer-800) + var(--spacer-300));
  }
}

}